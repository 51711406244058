import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDsMnM2WO79eI6tiZI5Ae0GOIA9rvh6BzQ",
  authDomain: "coinspectra-abff0.firebaseapp.com",
  projectId: "coinspectra-abff0",
  storageBucket: "coinspectra-abff0.appspot.com",
  messagingSenderId: "737248581947",
  appId: "1:737248581947:web:0683b6b1a5284228acff1e",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGq25Hh0nt1zy-G4L32-hYypt6LDS9ULc",
//   authDomain: "finance-test-5f328.firebaseapp.com",
//   projectId: "finance-test-5f328",
//   storageBucket: "finance-test-5f328.appspot.com",
//   messagingSenderId: "261981000578",
//   appId: "1:261981000578:web:ee913ada0c961a2803759b",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
